.select-component select {
  border: 1px solid var(--main-color-three);
  background: white;
  color: var(--paragraph-color);
  font-weight: normal;
  padding: 0 15px;
  width: 100%;
  height: 48px;
  font-size: 18px;
  min-width: 60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  background-image: linear-gradient(45deg, transparent 50%, var(--main-color-one) 50%),
  linear-gradient(135deg, var(--main-color-one) 50%, transparent 50%),
  linear-gradient(to right, white, white);
  background-position: calc(100% - 20px) 22px, calc(100% - 14px) 22px;
  background-size: 6px 6px, 6px 6px, 0;
  background-repeat: no-repeat;
}