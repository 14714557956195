.search-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .row-filters {
    width: 100%;
  }

  .expand-filters {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    padding: 0 15px;

    .expand-action {
      display: flex;
      align-items: center;
      gap: 1rem;
      word-spacing: 0;
      white-space: nowrap;
      cursor: pointer;
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #969899;
    }
  }

  .input-filter {
    .form-control {
      border-radius: 0;
      height: 45px;
      padding: inherit;
    }
    
    .form-control:focus {
      border-color: var(--main-color-one);
      box-shadow: none;
    }
  }

  .dropdown {
    .toggle-icon-button {
      border: 1px solid var(--main-color-three);
      width: auto;
    }

    button {
      background: transparent;
      width: 100% !important;
      .dropdown-content-btn {
        display: flex;
        justify-content: center;
        gap: 0.5rem;

        .label {
          font-weight: 600;
        }
      }
    }
  }

  select {
    font-size: 16px;
    background-color: transparent;
    font-weight: 600;
  }

  .filter-buttons {
    display: flex;
    gap: 1rem;
    width: 100%;
    
    .btn {
      padding: 0 15px;
    }
    
    .search-button {
      background-color: var(--main-color-two);
      line-height: 45px;
      color: #fff;
      width: 60%;
    }
    
    .clear-button {
      width: 40%;
    }
  }

  .clear-button {
    background-color: #fff;
    color: var(--main-color-one);
    border: 1px solid var(--main-color-one);
  }
  
  .search-buttons-responsive {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .search-filters {
    .expand-filters {
      justify-content: center;
      margin-bottom: 0.5rem;
    }
    
    .search-buttons {
      display: none;
    }
    
    .search-buttons-responsive {
      display: inherit;
      .filter-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        a, button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 850px) {
  .search-filters {
    .expand-filters {
      justify-content: center;
      margin-bottom: 1rem;
    }
    
    .search-buttons {
      display: none;
    }
    
    .search-buttons-responsive {
      display: inherit;
      .filter-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        a, button {
          width: 100%;
        }
      }
    }
  }
}
