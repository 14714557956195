:root {
  --main-color-one: #b8a196;
  --main-color-two: #dcc5ba;
  --main-color-three: #d8c6bb;
  --heading-color: #414141;
  --paragraph-color: #414141;
  --emphasis-color: #866f67;
  --light-bg-color: #f8f4f2;
  --border-color: #bca094;
  --heading-font: 'Dosis', sans-serif;
  --body-font: 'Dosis', sans-serif;
  --body-font-size: 15px;
  --line-height30: 1.8;
}

.vertical-widget {
  .expand-filters {
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  
  .search-buttons-vertical .filter-buttons{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    a, button {
      width: 100%;
    }
  }
}

@import './../../sass/bootstrap.css';
@import './../../sass/global/common';
@import './../../sass/elements/dropdown';
@import './../../sass/elements/min_max_select';
@import './../../sass/elements/select';
@import './../../sass/components/advance_search_filters';